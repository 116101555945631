import APIService from "./api";

/** This class will include all services defined in auth-service */
export default class AuthService extends APIService {
  service = "auth-service";

  async login(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "signin" },
      data,
      options
    );
    return response;
  }

  async changePassword(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "changePassword" },
      data,
      options
    );
    return response;
  }

  async getAccountDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      {
        service: "auth-service",
        method: "getAccountDetails"
      },
      data,
      { ...options, show_loader: false }
    );
    return response;
  }

  async getUserProfile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "getUserProfile" },
      data,
      options
    );
    return response;
  }

  async updateUserProfile(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "updateUserProfile" },
      data,
      options
    );
    return response;
  }

  async getInviteData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "getInviteData" },
      data,
      options
    );
    return response;
  }

  async updateProfilePic(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "updateProfilePic" },
      data,
      options
    );
    return response;
  }

  async forgotPasswordRequest(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "resetPasswordRequest" },
      data,
      options
    );
    return response;
  }

  async resetPassword(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "resetPassword" },
      data,
      options
    );
    return response;
  }

  async verifyEmailRequest(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "verifyEmailRequest" },
      data,
      options
    );
    return response;
  }

  async fetchApiKeys(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "fetchApiKeys" },
      data,
      options
    );
    return response;
  }

  async signup(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "signup" },
      data,
      options
    );
    return response;
  }

  async fetchUserInviteList(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "fetchUserInviteList" },
      data,
      options
    );
    return response;
  }

  async removeAPIkey(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "removeAPIkey" },
      data,
      options
    );
    return response;
  }

  async generateApiKeys(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "generateApiKeys" },
      data,
      options
    );
    return response;
  }

  async inviteUser(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "inviteUser" },
      data,
      options
    );
    return response;
  }

  async resendInvitation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    const response = await this.api(
      { service: this.service, method: "resendInvitation" },
      data,
      options
    );
    return response;
  }
}
