import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import constants from "@/utils/constants";
import Cookies from "js-cookie";
// import router from "@/router";

export default class APIService {
  api = async (
    headers,
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) => {
    try {
      const realm =
        Cookies.get("REALM_TOKEN") || process.env.VUE_APP_POLY_REALM;
      const res = await axios({
        method: "post",
        url: process.env.VUE_APP_API,
        headers: {
          authorization: `bearer ${realm}`,
          ...options.http_headers
        },
        data: {
          header: {
            lang: localStorage.getItem("lang") || "en",
            method: headers.method,
            service: headers.service,
            token:
              Cookies.get(useJwt.jwtConfig.storageTokenKeyName) || undefined,
            account: Cookies.get("USER_ACCOUNT_ID") || undefined,
            version: options.api_version || process.env.VUE_APP_VERSION
          },
          data
        }
      });

      // Server errors handling
      if (
        res.data.error &&
        res.data.error.code &&
        constants.LOGOUT_ERROR_CODES.includes(res.data.error.code)
      ) {
        return this.logout(1);
      }
      return res.data;
    } catch (err) {
      return {
        error: { header: { status: "ERROR" }, message: "Something went wrong" }
      };
    }
  };

  logout(props) {
    Cookies.remove(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    Cookies.remove("USER_ACCOUNT_ID");
    Cookies.remove("REALM_TOKEN");
    localStorage.removeItem("USER_PROFILE_DETAILS");
    localStorage.removeItem("userData");
    if (props) {
      this.reloadWindow();
    }
  }

  reloadWindow() {
    const me = this;
    window.location.reload(!!me);
  }
}
