import Vue from "vue";
import VueRouter from "vue-router";
import useJwt from "@/auth/jwt/useJwt";
import VueI18n from "@/libs/i18n";
import Cookies from "js-cookie";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => {
        return import("@/views/Dashboard/Dashboard.vue");
      },
      meta: {
        pageTitle: VueI18n.t("Dashboard"),
        breadcrumb: [
          {
            text: VueI18n.t("Dashboard"),
            active: true
          }
        ]
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => {
        return import("@/views/authentification-pages/LoginPage.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/welcome-user",
      name: "welcome-user",
      component: () => {
        return import("@/views/user-onboarding/WelcomeUser.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/new-user-onboarding",
      name: "new-user-onboarding",
      component: () => {
        return import("@/views/user-onboarding/NewUserOnboarding.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/forgot-password",
      name: "forgot-password-page",
      component: () => {
        return import("@/views/authentification-pages/ForgotPasswordPage.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/forgot-password-confirm-code",
      name: "forgot-password-confirmation-code-page",
      component: () => {
        return import(
          "@/views/authentification-pages/ForgotPasswordConfirmationCodePage.vue"
        );
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/forgot-password-set-new-password",
      name: "forgot-password-set-new-password-page",
      component: () => {
        return import(
          "@/views/authentification-pages/ForgotPasswordSetNewPasswordPage.vue"
        );
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/forgot-password-success",
      name: "forgot-password-success-page",
      component: () => {
        return import(
          "@/views/authentification-pages/ForgotPasswordSuccessPage.vue"
        );
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/view-profile",
      name: "view-profile",
      component: () => {
        return import("@/views/profile/ViewProfile.vue");
      },
      meta: {
        pageTitle: VueI18n.t("Profiles"),
        breadcrumb: [
          {
            text: VueI18n.t("Profiles"),
            active: true
          }
        ]
      }
    },
    {
      path: "/update-profile",
      name: "update-profile",
      component: () => {
        return import("@/views/profile/UpdateProfile.vue");
      },
      meta: {
        pageTitle: VueI18n.t("UpdateProfile"),
        breadcrumb: [
          {
            text: VueI18n.t("UpdateProfile"),
            active: true
          }
        ]
      }
    },
    {
      path: "/transaction-list",
      name: "transaction-list",
      props: true,
      component: () => {
        return import("@/views/transaction-pages/TransactionList.vue");
      },
      meta: {
        pageTitle: VueI18n.t("TransactionList"),
        breadcrumb: [
          {
            text: VueI18n.t("TransactionList"),
            active: true
          }
        ]
      }
    },
    {
      path: "/transaction-list/:merchant_account_id",
      name: "transaction-list",
      props: true,
      component: () => {
        return import("@/views/transaction-pages/TransactionList.vue");
      },
      meta: {
        pageTitle: VueI18n.t("TransactionList"),
        breadcrumb: [
          {
            text: VueI18n.t("TransactionList"),
            active: true
          }
        ]
      }
    },
    {
      path: "/ticket-page",
      name: "ticket-page",
      component: () => {
        return import("@/views/TicketPage/TicketPage.vue");
      },
      meta: {
        pageTitle: VueI18n.t("Ticket"),
        breadcrumb: [
          {
            text: VueI18n.t("Ticket"),
            active: true
          }
        ]
      }
    },
    {
      path: "/user-list",
      name: "user-list",
      component: () => {
        return import("@/views/user-onboarding/UserList.vue");
      }
    },
    {
      path: "/new-user-onboardinng",
      name: "new-user-onboardinng",
      component: () => {
        return import("@/views/user-onboarding/NewUserOnboarding.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },

    {
      path: "/account-page",
      name: "account-page",
      component: () => {
        return import("@/views/Account/Account.vue");
      },
      props: true,
      meta: {
        pageTitle: VueI18n.t("Account"),
        breadcrumb: [
          {
            text: VueI18n.t("Account"),
            routeName: "account-page",
            active: true
          }
        ]
      }
    },
    {
      path: "/account-list-page/:id/:merchant_name",
      name: "account-list-page",
      component: () => {
        return import("@/views/Account/AccountListPage.vue");
      },
      meta: {
        pageTitle: VueI18n.t("AccountList"),
        breadcrumb: [
          {
            text: VueI18n.t("AccountList"),
            routeName: "account-page",
            to: { name: "account-page" }
          }
        ]
      }
    },

    {
      path: "/All-users-list",
      name: "all-users-list",
      component: () => {
        return import("@/views/Dashboard/All-users-list/All-Users.vue");
      },
      meta: {
        pageTitle: VueI18n.t("AllUsers"),
        breadcrumb: [
          {
            text: "All Users List",
            active: true
          }
        ]
      }
    },
    {
      path: "/Invitations",
      name: "Invitations",
      component: () => {
        return import("@/views/Dashboard/Invitations/invitations.vue");
      },
      meta: {
        pageTitle: VueI18n.t("Invitations"),
        breadcrumb: [
          {
            text: VueI18n.t("InvitationsList"),
            active: true
          }
        ]
      }
    },
    {
      path: "/roles",
      name: "roles",
      component: () => {
        return import("@/views/Dashboard/Roles/Roles.vue");
      },
      meta: {
        pageTitle: VueI18n.t("Roles"),
        breadcrumb: []
      }
    },
    {
      path: "/merchant-simulator",
      name: "merchant-simulator",
      component: () => {
        return import("@/views/MerchantSimulator/MerchantSimulator.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => {
        return import("@/views/error-pages/Error404Page.vue");
      },
      meta: {
        layout: "full",
        allowUnauthorized: true
      }
    },
    {
      path: "*",
      redirect: "error-404",
      meta: {
        allowUnauthorized: true
      }
    }
  ]
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

function isAuthorized() {
  return !!Cookies.get(useJwt.jwtConfig.storageTokenKeyName);
}

router.beforeEach((to, from, next) => {
  if (to.meta.allowUnauthorized || isAuthorized()) {
    next();
  } else next({ path: "/login", query: { redirect: to.fullPath } });
});

export default router;
