import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueColumnsResizable from "vue-columns-resizable";
import VueGoodTablePlugin from "vue-good-table";
import i18n from "@/libs/i18n";
import VueApexCharts from "vue-apexcharts";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
// 3rd party plugins
import "@axios";
import "@/libs/clipboard";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

Vue.use(VueGoodTablePlugin);

Vue.use(VueColumnsResizable);
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard
// import core styles
require("@core/scss/core.scss");

Vue.use(VueApexCharts);

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

Vue.filter("accountNumberFormat", (value) => {
  if (!value) return "";
  const formattedAccountNumber = value
    .replace(/\D/g, "")
    .replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g, "$1 $2 $3 $4");
  return formattedAccountNumber;
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => {
    return h(App);
  }
}).$mount("#app");
